import React, { useState } from 'react'

import {
  Alert,
  Link,
  List,
  ListItem,
  LockIcon,
  Paragraph
} from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import IconHTMLExample from '../../examples/html/IconHTMLExample'
import { Code } from '../../components/Code'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => {
  const [isVisible, setIsVisible] = useState(true)

  const onClose = () => {
    setIsVisible(false)
    setTimeout(() => setIsVisible(true), 1000)
  }

  return (
    <Content
      heading="Alert"
      components={[{ component: Alert, restElement: true }]}
      status={[{ type: 'accessible', version: '15.1.0' }]}
    >
      <Section>
        <Paragraph>
          <Code>Alert</Code>-komponenttia käytetään varoittamaan tiedon tilasta.
          Käyttötapauksesta riippuen <Code>Alert</Code> voi olla joko:
        </Paragraph>
        <List variant="unordered">
          <ListItem>
            Dynaaminen ilmoitus. Esimerkiksi jos monisivuisen lomakkeen
            lähetyksen yhteydessä lomakkeella havaitaan virheitä, eikä toimintoa
            voida jatkaa ennen kuin käyttäjä on korjannut virheet.
          </ListItem>
          <ListItem>
            Pitempiaikainen, koko sivua koskeva erityisen tärkeä maininta sivun
            tietojen tilasta, joka luetaan ruudunlukijalle heti sivun ladattua.
          </ListItem>
        </List>
        <Paragraph>
          Jos käyttäjälle halutaan tarjota pysyvämpää taustatietoa tai varoittaa
          käyttäjää toiminnan mahdollisista seurauksista, käytetään{' '}
          <Code>Help</Code>-komponenttia.
        </Paragraph>
        <Playground>
          <>
            {isVisible && (
              <Alert
                color="danger"
                onCloseButtonClick={onClose}
              >
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              </Alert>
            )}
            <Alert
              color="danger"
              icon={<LockIcon />}
            >
              Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed
              sapien.
            </Alert>
            <Alert
              color="secondary"
              noMargin
            >
              Sed arcu lectus auctor vitae, consectetuer et venenatis eget
              velit.
            </Alert>
          </>
        </Playground>
        <Playground format="html">
          <>
            <div
              className="alert alert-danger"
              role="alert"
            >
              <IconHTMLExample
                className="alert-icon"
                name="ErrorIcon"
                size="xxl"
              />
              <div className="alert-text">
                Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed
                sapien.
              </div>
            </div>
            <div
              className="alert alert-secondary mb-0"
              role="alert"
            >
              <IconHTMLExample
                className="alert-icon"
                name="InfoCircleIcon"
                size="xxl"
              />
              <div className="alert-text">
                Sed arcu lectus auctor vitae, consectetuer et venenatis eget
                velit.
              </div>
            </div>
          </>
        </Playground>
      </Section>
      <Section title="Saavutettavuus">
        <Paragraph>
          Elementti sisältää semanttisen roolin (<Code>role=alert</Code>).
        </Paragraph>
        <List variant="unordered">
          <ListItem>
            Jos sivulla on <Code>Alert</Code> sivulle saavuttaessa, se luetaan
            heti sivulle tullessa "Ilmoitus: xxxx". Tämän lisäksi{' '}
            <Code>Alert</Code>issa oleva teksti luetaan osana sivun sisältöä
            normaalilla paikallaan.
          </ListItem>
          <ListItem>
            Jos <Code>Alert</Code> lisätään sivulle dynaamisesti, se luetaan
            heti sen ilmestyessä. Jotta ruudunlukija lukee <Code>Alert</Code>in
            oikein, on sivun DOMissa oltava olemassa container-elementti, jonka
            childreniksi <Code>Alert</Code> lisätään. Esimerkiksi Modalin
            sisällä valmiiksi olevaa <Code>Alert</Code>ia ei tunnisteta, kun
            Modal avataan. Katso ohjeistus "Anna palaute suoritetuista
            toiminnoista"{' '}
            <Link href="/saavutettavuus/kehittajille">Saavutettavuus</Link>
            -sivulta.
          </ListItem>
        </List>
        <Paragraph>
          Huomioitava myös, että eri väriset <Code>Alert</Code>it eivät eroa
          toisistaan ruudunlukijalla luettaessa.
        </Paragraph>
      </Section>
    </Content>
  )
}

export default Page
